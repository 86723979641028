import React, { Component } from "react";
import '../scss/nhl.scoped.scss';
import { Dropdown } from 'semantic-ui-react'

let teamDropdown = [];

class NHL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [],
    };
  }
  render() {
    let teamsApiCall = async (apiCall) => {
      if(teamDropdown.length === 0 ) {
        const response = await fetch("https://statsapi.web.nhl.com/api/v1/teams");
        const data = await response.json();
        this.setState({
          teams: data["teams"]
        })
        teamDropdown = [];
        this.state.teams.map((team, index) => (
          teamDropdown.push({
            "key": team.id,
            "text": team.name,
            "value": team.id,
            "image": { avatar: true, src: "/images/portfolio/NHL/" + team.id + ".svg" }
          })
        ))
      }
    }; 
    let teamApiCall = async (id) => {
      if(id !== "") {
        const statResponse = await fetch("https://statsapi.web.nhl.com/api/v1/teams/" + id + "/stats");
        const statData = await statResponse.json();
        this.setState({
          stats : statData["stats"][0].splits[0].stat,
          ratings: statData["stats"][1].splits[0].stat
        })

        let date = new Date();
        const startDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
        date.setDate(date.getDate() + 7);
        const endDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
        const scheduleResponse = await fetch("https://statsapi.web.nhl.com/api/v1/schedule?teamId=" + id + "&startDate=" + startDate + "&endDate=" + endDate);
        const scheduleData = await scheduleResponse.json();
        this.setState({
          schedule : scheduleData["dates"]
        })
      } else {
        this.setState({
          team : null
        })
      }
    };
    teamsApiCall();
    return (
      <div id="nhl" className="bg-gray-100 h-screen pt-20 lg:pt-48 pr-5 pl-5">
        <h2 className="text-4xl flex justify-center pb-5 text-center"> Work in progress. Pick a team from the dropdown for more information</h2>
        <div className="flex justify-center">
          <Dropdown
                placeholder='Select Team'
                selection
                fluid
                search
                clearable
                options={teamDropdown}
                onChange={(event, data) => teamApiCall(data.value)}
              />
        </div>
        <div className="teamStats pt-10 flex justify-center">
          {this.state.stats && (
            <div className="text-2xl">
              <h2 className="pb-2 text-center">Current Stats</h2>
              <h3>Record: {this.state.stats.wins} - {this.state.stats.losses} - {this.state.stats.ot}</h3>
              <h3>Points: {this.state.stats.pts}</h3>
              <h3>Ranking: {this.state.ratings.pts}</h3>
              
            </div>
          )}
        </div>
        <div className="teamSchedule pt-10 flex justify-center">
          {this.state.schedule && (
            <div className="md:text-2xl">
              <h2 className="pb-2 text-center">Upcoming Games over the next week</h2>
              {this.state.schedule.map((game) => (
                <div className="text-center w-screen md:w-auto p-2">
                  <p>
                    <span className="float-left pl-5 md:pl-10" 
                    style={{backgroundImage: "url(/images/portfolio/NHL/" + game.games[0].teams.away.team.id + ".svg)",
                    backgroundPosition: "left",
                    backgroundSize: "contain",
                    backgroundRepeat:"no-repeat"}}>
                      {game.games[0].teams.away.team.name}
                    </span> 
                    <span className="md:pr-5 md:pl-5">vs</span>
                    <span className="float-right pr-5 md:pr-10" style={{backgroundImage: "url(/images/portfolio/NHL/" + game.games[0].teams.home.team.id + ".svg)",
                    backgroundPosition: "right",
                    backgroundSize: "contain",
                    backgroundRepeat:"no-repeat"}}>
                      {game.games[0].teams.home.team.name}
                    </span>
                  </p>
                  <p>
                    Date: {game.date.split('-')[1]} / {game.date.split('-')[2]} / {game.date.split('-')[0]}
                    <br />
                    Time: {new Date(game.games[0].gameDate).toLocaleTimeString()}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default NHL;